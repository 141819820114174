import $ from 'jquery';
import lity from 'lity';
// import 'slick-carousel';
// import { createFocusTrap } from 'focus-trap';
import debounce from 'lodash/debounce';
import LazyLoad from "vanilla-lazyload";


// Remove passive listeners from https://stackoverflow.com/questions/60357083/does-not-use-passive-listeners-to-improve-scrolling-performance-lighthouse-repo
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

// Scroll Direction needed for Header
let setScrollProps = debounce((scroll) => {
    document.documentElement.dataset.scrollDirection = document.documentElement.dataset.scroll > window.scrollY ? 'up' : 'down';
    document.documentElement.dataset.scroll = window.scrollY;
}, 100, { leading: true, maxWait: 500 });


//Scrolling
(function () {
    setScrollProps();
    document.onscroll = setScrollProps;
})()


// Lazy Loading Images
// DO NOT REMOVE OR IMAGES WILL NOT LOAD
const lazyContent = new LazyLoad({
    elements_selector: "img.lazy",
    use_native: true
});

const lazyBackground = new LazyLoad({
    elements_selector: "*:not(img).lazy"
});


// Lity automatic popups
const timedModals = document.querySelectorAll('.lity-automatic');
if (timedModals) { 
    timedModals.forEach((element) => { 
        if (localStorage.getItem('modalSeen') != 'shown') {
            setTimeout(() => {
              lity(element); 
            }, element.dataset.lityAutomaticSeconds);
          
            localStorage.setItem('modalSeen','shown')
        }
    });
};

// Segment Tracking
// Needs to come after Segment tracking script (in head)
$( document ).ready(function() { 
    // Button Tracking
    $('.btn').click(function () {
        var clickedElement = $(this);

        analytics.track('CTA Clicked ', {
            buttonText: clickedElement.text(),
            elementId: clickedElement.attr('id'),
            href: clickedElement.attr('href'),
            method: clickedElement.data('ss-method'),
            description: clickedElement.data('ss-description')
        });
    });

    // Button Tracking
    $('.socialLink').click(function () {
        var clickedElement = $(this);

        analytics.track('Blog Post Shared', {
            buttonText: clickedElement.text()
        });
    });

    // Banner Link Tracking
    $('.bannerNotification a').click(function () {
        var clickedElement = $(this);

        analytics.track('Banner Notification Link Clicked', {
            buttonText: clickedElement.text()
        });
    });

    // Modal Tracking
    $('[data-lity]').click(function () {
        var clickedElement = $(this);

        analytics.track('Modal Clicked', {
            href: clickedElement.attr('href'),
            title: clickedElement.data('ss-title'),
            type: clickedElement.data('ss-type')
        });
    });

    // Resource Search Tracking
    $('.resourceSearch').change(function () {
        var searchElement = $(this);

        analytics.track('Resource Search', {
            query: searchElement.val()
        })
    });

    // Site Search Tracking
    $('.hs-search-field__input').change(function () {
        var searchElement = $(this);

        analytics.track('Site Search', {
            query: searchElement.val()
        })
    });    

    // Industry Modal Tracking
    $('.industryModal__link').click(function () {
        var clickedElement = $(this);

        analytics.track('Industry Modal Link Clicked ', {
            buttonText: clickedElement.text(),
            href: clickedElement.attr('href')
        });
    });    

    // FAQ Tracking
    $('.faq-section .collapsed').click(function () {
        var clickedElement = $(this);
        var clickedElementText = $(this).find('h3').text();

        analytics.track('FAQ Opened', {
            buttonText: clickedElementText
        });
    });       
});

// Form Validation
$('document').ready(function () {

    $.fn.serializeObject = function()
    {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    const mappings = {
        'firstname': 'firstName',
        'lastname': 'lastName',
        'email': 'email',
        'phone': 'phone',
        'mobilephone': 'phone',
    };

    $('body').on('hsvalidatedsubmit', '.hs-form', function () {

        var form = $(this).serializeArray();

        var formData = form.reduce( (previousValue, currentValue) =>{
            if (currentValue.name == 'hs_context') {
                return previousValue;
            }
            var key = mappings[currentValue.name] || currentValue.name;
            previousValue[key] = currentValue.value;
            return previousValue;
        }, {} );

        var data = {
            'form_id': this.dataset.formId,
            'portal_id': this.dataset.portalId,
            'page_id': hsVars.page_id,
            'page_title': document.title,
            'form_data': formData
        };

        // console.log(formData);

        analytics.identify(formData);
        // _cio.identify(formData);
        analytics.track('HubSpot Form Submitted', data);

    });
});